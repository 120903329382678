import React, { Component } from "react"
import { BrowserRouter as Router } from "react-router-dom"

import HomePage from "./HomePage"
import Nav from "./Nav"
import About from "./About"
import Projects from "./Projects"
import Contact from "./Contact"
import ComingSoon from "./ComingSoon"
import "./App.css"

class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      fixed: false,
      height: 0,
      isContactActive: false,
    }
  }

  componentDidMount() {
    let vh = window.innerHeight * 0.01
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`)
    window.onscroll = this.handleScroll
    this.setState({ height: window.innerHeight })
    window.addEventListener("resize", () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty("--vh", `${vh}px`)
    })
  }

  handleScroll = () => {
    let contactVisible =
      document.body.offsetHeight - (window.scrollY + window.innerHeight)
    if (window.pageYOffset > this.state.height && contactVisible > 86) {
      this.setState({ isContactActive: false, fixed: true })
    } else if (window.pageYOffset > this.state.height && contactVisible < 86) {
      this.setState({ isContactActive: true, fixed: true })
    } else if (window.pageYOffset < this.state.height) {
      this.setState({ fixed: false })
    }
  }

  setToActive = () => {
    this.setState({ isContactActive: true })
    console.log(this.state)
  }
  render() {
    return (
      <Router>
        <div className="portfolio">
          <HomePage />
          <Nav
            className={this.state.fixed ? "nav_bar fixed" : "nav_bar"}
            contactClass={
              this.state.isContactActive ? "section_name on" : "section_name"
            }
            dropdownContactClass={
              this.state.isContactActive
                ? "dropdown_sections on"
                : "dropdown_sections"
            }
            ref={(ref) => (this.navRef = ref)}
          />
          <About />
          <Projects />
          <Contact />
        </div>
        {/* <ComingSoon /> */}
      </Router>
    )
  }
}

export default App
