import React, { Component } from "react"
import { Link as Route } from "react-router-dom"
import { Link } from "react-scroll"

import "./Nav.css"

export default class Dropdown extends Component {
  constructor(props) {
    super(props)
    this.state = { isVisible: false }
  }

  toggleDropdown = () => {
    this.setState({ isVisible: !this.state.isVisible })
  }

  handleSectionScroll = () => {
    this.toggleDropdown()
    this.props.setToActive()
  }
  render() {
    return (
      <>
        <div className="menu" onClick={this.toggleDropdown}>
          <div />
          <div />
          <div />
        </div>
        <div
          className={
            this.state.isVisible ? "dropdown visible" : "dropdown hidden"
          }
        >
          <Link
            onClick={this.toggleDropdown}
            to="homepage"
            style={{ textDecoration: "none" }}
            smooth="true"
            activeClass="active"
          >
            <div className="dropdown_sections home">home</div>
          </Link>
          <Link
            onClick={this.toggleDropdown}
            to="about_section"
            style={{ textDecoration: "none" }}
            smooth="true"
            spy={true}
            activeClass="active"
          >
            <div className="dropdown_sections">about</div>
          </Link>
          <Link
            onClick={this.toggleDropdown}
            to="project_section"
            style={{ textDecoration: "none" }}
            smooth="true"
            spy={true}
            activeClass="active"
          >
            <div className="dropdown_sections">work</div>
          </Link>
          <Link
            onClick={this.toggleDropdown}
            to="mock_bottom"
            style={{ textDecoration: "none" }}
            smooth="true"
            spy={true}
            activeClass="active"
          >
            <div className={this.props.dropdownContactClass}>contact</div>
          </Link>
          <Route
            to="//yh2n.github.io/Yohann_Potico/"
            target="_blank"
            style={{ textDecoration: "none", color: "grey" }}
          >
            <div className="dropdown_sections resume">resume</div>
          </Route>
        </div>
      </>
    )
  }
}
