import React, { Component } from "react"
import { Link } from "react-scroll"
import { Link as Route } from "react-router-dom"

import Dropdown from "./Dropdown"
import "./Nav.css"

// const Nav = React.forwardRef((props, ref) => {
export class Nav extends Component {
  render() {
    return (
      <nav className={this.props.className}>
        <Link
          to="homepage"
          style={{ textDecoration: "none" }}
          smooth="true"
          duration={400}
          spy={true}
          className="section_name"
          activeClass="active"
        >
          <div className="sections home">home</div>
        </Link>
        <Link
          to="about_section"
          style={{ textDecoration: "none" }}
          smooth="true"
          duration={400}
          spy={true}
          className="section_name"
          activeClass="active"
        >
          <div className="sections">about</div>
        </Link>
        <Link
          to="project_section"
          style={{ textDecoration: "none" }}
          smooth="true"
          duration={400}
          spy={true}
          className="section_name"
          activeClass="active"
        >
          <div className="sections">work</div>
        </Link>
        <Link
          to="mock_bottom"
          style={{ textDecoration: "none" }}
          smooth="true"
          duration={400}
          spy={true}
          className={this.props.contactClass}
          activeClass="active"
          onClick={this.props.setToActive}
        >
          <div className="sections">contact</div>
        </Link>
        <Route
          to="//yh2n.github.io/Yohann_Potico/"
          target="_blank"
          className="section_name"
          style={{ textDecoration: "none", color: "grey" }}
        >
          <div className="sections resume">resume</div>
        </Route>
        <Dropdown dropdownContactClass={this.props.dropdownContactClass} />
      </nav>
    )
  }
}

export default Nav
