import React, { Component } from "react"
import { Link, Element } from "react-scroll"
import { Link as Route } from "react-router-dom"

import "./Contact.css"

export default class Contact extends Component {
  constructor(props) {
    super(props)

    this.state = { isActive: false }
  }
  render() {
    return (
      <Element name="contact_container" className="contact_container">
        <div className="mock_div" />
        <div className="contact">
          <Link to="about_section" smooth="true">
            <div className="menu_arrows">
              <i className="fas fa-angle-double-up" />
            </div>
          </Link>
          <div className="contact_title">contact</div>
          <div className="contact_logos">
            <div className="github" title="<> github </>">
              <Route
                className="github_link"
                to="//github.com/yh2n"
                target="_blank"
              >
                <i className="fab fa-github" />
              </Route>
            </div>
            <div className="linkedin" title="Let's connect">
              <Route
                className="linkedin_link"
                to="//linkedin.com/in/yohann-potico/"
                target="_blank"
              >
                <i className="fab fa-linkedin" />
              </Route>
            </div>
            <div className="email" title="Drop me a line...">
              <Route
                className="email_link"
                to="//mailto:yohann.potico@gmail.com"
                target="_blank"
              >
                <i className="far fa-envelope" />
              </Route>
            </div>
          </div>
          <div className="copyright">
            <div className="credits mock_element">
              Photo credits: Robin Rhode
            </div>
            <div className="contact_name">
              <span>Yohann Potico </span>© 2019
            </div>
            <div className="credits">
              Photo credits:{" "}
              <Route
                to="//www.lehmannmaupin.com/artists/robin-rhode"
                target="_blank"
                style={{
                  textDecoration: "none",
                  color: "grey",
                }}
              >
                Robin Rhode
              </Route>
            </div>
          </div>
        </div>
        <footer className="mock_bottom" />
      </Element>
    )
  }
}
