import React from "react"
import { Link, Element } from "react-scroll"

import "./HomePage.css"

export default function HomePage() {
  return (
    <Element name="homepage" className="homepage">
      <div className="intro_container">
        <h1>
          <span className="name">Yohann Potico</span>
        </h1>
        <h1 className="presentation_position">software engineer</h1>
      </div>
      <Link to="about_section" smooth="true" duration={400}>
        <i className="fas fa-angle-down" />
      </Link>
    </Element>
  )
}
